import { RouteConfig } from './types';
import RouterPath from './RouterPath';
import { lazy } from 'react';

const PageDeveloper = lazy(() => import('src/view/landingPage/Developer'))

export const routesConfig: RouteConfig[] = [
  {
    path: '*',
    component: () => <>404 Not found</>,
  },
];

export const routesLanding: RouteConfig[] = [
  {
    exact: true,
    path: RouterPath.PAGE_FOR_DEVELOPER,
    component: () => <PageDeveloper />,
  },
];
