import { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import history from './history';
import { routesLanding } from './router/router.config';
import CustomRoute from "src/router/CustomRouter"

const Fallback = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', position: 'absolute', inset: 0, backgroundColor: '#6a33b7' }} />
  );
};

const Routes = () => {
  const routeComponents = routesLanding.map((item, key) => <CustomRoute {...item} key={key} />);
  return (
    <Router history={history}>
      <Suspense fallback={<Fallback />}>
        <Switch>
          {routeComponents}
        </Switch>
      </Suspense>
    </Router>
  );
}
export default Routes;
