import { memo } from 'react';
import { Route } from 'react-router-dom';
import { RouteConfig } from './types';

const CustomRoute = ({ isAuthenticated, redirectPath, component: Component, ...rest }: RouteConfig) => {

  return (
    <Route {...rest} component={Component}>
      {<Component />}
    </Route>
  );
};

export default memo(CustomRoute);
